import React from 'react';

const Sql = () => {
	return (
		<>
			<p>
				-- vérifier de temps en temps les doublons dans les sources, c&apos;est pas normal
				et faut fusionner souvent <br />
				select ms_src_id , ms_import_id , count(*) from media_src ms group by ms_src_id ,
				ms_import_id having count(*) {'>'} 1
			</p>
			<p>
				-- pour les auteurs : virer les roles non utile
				<br />
				update media_authors ma set ma_usr_id_state = 2 where ma_author_role in( 95, 6, 91,
				94, 95, 97, 100) and ma_usr_id_state =0
			</p>
			<p>
				-- pour les auteurs : accepter directement les auteurs déja avec un autre roles
				validé sur le meme item
				<br />
				update media_authors ma set ma_usr_id =null, ma_notified =true where ma_usr_id_state
				= 0 and ma_usr_id is not null and exists ( select 1 from media_authors ma1 where
				ma.ma_author_id = ma1 .ma_author_id and ma1.ma_usr_id is null )
			</p>
			<p>
				-- pour les editions refuser auto les prix
				<br />
				update media_editions_users set meu_price_state =2 where meu_price_state = 0
			</p>
		</>
	);
};
export default Sql;
